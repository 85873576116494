import React from "react";
import styled from "styled-components";
import { graphql, PageProps } from "gatsby";

import { Container } from "@util/standard";
import Hero from "@shared/hero";
import { BlocksContent, HeroCarousel, Breadcrumb } from "@global";
import { TABLET_BREAKPOINT, slug } from "@util/constants";
import SEO from "@shared/seo";
import Layout from "@shared/layout";
import { BreadcrumbPageContext } from "@util/types";
import JotformEmbed from "react-jotform-embed";
import { Query } from "@graphql-types";

interface Props extends PageProps {
  data: Query;
  pageContext: BreadcrumbPageContext;
}

const BlocksContentContainer = styled(Container)`
  display: flex;
  width: 40%;
  flex-direction: column;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 100%;
    margin: auto;
  }
`;

const ContentContainer = styled(Container)`
  width: 80%;
  flex-direction: row;
  margin: 5% auto;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    flex-direction: column;
  }
`;

const CorporateGifting = ({ pageContext, data }: Props) => {
  const pageData = data.allSanityCorporateGiftingPage.nodes[0];
  if (pageData == null) return null;
  const parentCategoryCrumb = {
    crumbLabel: "Gifting",
    pathname: `/${slug.gifting}`,
  };
  return (
    <>
      <SEO seoData={pageData.seo} />
      <Layout>
        <Container flexDirection="column">
          {/* Hero */}
          {pageData &&
          pageData.additionalHeros &&
          pageData.additionalHeros.length > 0 ? (
            <HeroCarousel
              hero={pageData?.hero}
              additionalHeros={pageData.additionalHeros}
            />
          ) : (
            <Hero sanityHero={pageData?.hero} />
          )}
          <Breadcrumb
            parentCrumb={parentCategoryCrumb}
            pageContext={pageContext}
          />

          {/* Content */}
          <ContentContainer>
            <BlocksContentContainer>
              {pageData.contactInfo?._rawBlocks && (
                <BlocksContent blocks={pageData.contactInfo._rawBlocks} />
              )}
            </BlocksContentContainer>
            <JotformEmbed
              scrolling
              src={process.env.GATSBY_JOTFORM_CORPORATEGIFTING}
            />
          </ContentContainer>
        </Container>
      </Layout>
    </>
  );
};

export default CorporateGifting;

export const query = graphql`
  query allSanityGiftingCorporate($iban: String) {
    allSanityCorporateGiftingPage(filter: { region: { iban: { eq: $iban } } }) {
      nodes {
        hero {
          ...sanityHero
        }
        additionalHeros {
          ...sanityHero
        }
        contactInfo {
          _rawBlocks
        }
        seo {
          ...sanitySeo
        }
      }
    }
  }
`;
